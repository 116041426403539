import cls from 'classnames';
import NextImage, {
  ImageLoader,
  ImageProps as NextImageProps,
} from 'next/image';
import * as R from 'ramda';
import { FC, useMemo, useState } from 'react';

import { customLoader as contentfulLoader } from '@/lib/utils';

import Skeleton from '../skeleton';
import styles from './styles';

type ImageProps = {
  src: string;
  alt?: string;
  loadImmediately?: boolean;
  objectFit?: NextImageProps['objectFit'];
  objectPosition?: NextImageProps['objectPosition'];
  basicLoader?: boolean;
  loader?: ImageLoader;
  className?: string;
} & (
  | {
      width?: never;
      height?: never;
      useIntrinsicSize?: false;
    }
  | {
      width: number | string;
      height: number | string;
      useIntrinsicSize: true;
    }
);

type LayoutProps =
  | {
      width?: never;
      height?: never;
      layout: 'fill';
      objectFit?: NextImageProps['objectFit'];
      objectPosition?: NextImageProps['objectPosition'];
    }
  | {
      width: number | string;
      height: number | string;
      layout?: 'intrinsic';
    };

const Image: FC<ImageProps> = ({
  src,
  alt,
  useIntrinsicSize = false,
  objectFit,
  width,
  height,
  loadImmediately = false,
  basicLoader = false,
  loader,
  className = '',
  objectPosition,
}) => {
  const [hasLoaded, setHasLoaded] = useState(false);

  const isSvgFile = useMemo(() => {
    // newsletter component returns object in src
    if (typeof src === 'object') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return src.src.toString().includes('.svg');
    }

    return src?.includes('.svg');
  }, [src]);

  const isDefaultLoader = isSvgFile || basicLoader;
  const predefinedLoader = isDefaultLoader ? R.prop('src') : contentfulLoader;

  const layoutProps: LayoutProps = useIntrinsicSize
    ? {
        layout: 'intrinsic',
        // type inference doesn't work somehow, so we have to define defaults
        // even though the width/height, can never be undefined if useIntrinsicSize is true
        width: width ?? 0,
        height: height ?? 0,
      }
    : {
        layout: 'fill',
        objectFit,
        objectPosition,
      };

  return (
    <div
      className={cls(
        styles.wrapper,
        layoutProps.layout === 'fill' && 'h-full',
        className,
      )}
    >
      {!hasLoaded && <Skeleton className={className} />}

      <NextImage
        onLoadingComplete={() => {
          setHasLoaded(true);
        }}
        loader={loader || predefinedLoader}
        unoptimized={basicLoader}
        src={src}
        {...layoutProps}
        alt={alt}
        loading={loadImmediately ? 'eager' : 'lazy'}
        lazyBoundary="50% 50% 50% 50%"
      />
    </div>
  );
};

export default Image;
