import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import usePlayer from '@/lib/hooks/use-player';
import { useStoreDispatch } from '@/store';
import {
  selectPlayerCurrentAudio,
  selectPlayerIsMuted,
  selectPlayerVolume,
  setCurrentAudio,
  setIsAudioPlaying,
} from '@/store/slices/player';
import { setCurrentPlayingVideo } from '@/store/slices/video';
import {
  ContentFeedEntrySoundpiece,
  PlaylistItem as PlaylistItemProps,
} from '@/types/views/generic';

export type AudioTimeUpdateEvent = {
  currentTime: number;
  duration: number;
  isPaused: boolean;
};

type UseAudioPlayer = {
  onPlay: (
    index: number,
    localEntry: ContentFeedEntrySoundpiece | PlaylistItemProps,
  ) => void;
  onPause: () => void;
  onStop: () => void;
  onResume: () => void;
  setIsAutoPlay: (isAutoPlay: boolean) => void;
  skipAudio: (step: number) => void;
  changeEvent?: AudioTimeUpdateEvent;
  addToAutoPlaylist: (
    entry: PlaylistItemProps | ContentFeedEntrySoundpiece,
    index?: number,
  ) => void;
};

type UseAudioPlayerProps = {
  autoPlaylist?: PlaylistItemProps[] | ContentFeedEntrySoundpiece[];
};

// Placed in variables so that is only instanced once.
// Reused for all usages of useAudioPlayer
let audio: HTMLAudioElement;
let isAutoPlay: boolean;

const useAudioPlayer = ({
  autoPlaylist,
}: UseAudioPlayerProps = {}): UseAudioPlayer => {
  const radioPlayer = usePlayer();

  const localAutoPlaylist =
    autoPlaylist ?? ([] as PlaylistItemProps[] | ContentFeedEntrySoundpiece[]);

  const isMuted = useSelector(selectPlayerIsMuted);
  const volume = useSelector(selectPlayerVolume);
  const currentAudio = useSelector(selectPlayerCurrentAudio);

  const dispatch = useStoreDispatch();

  const [changeEvent, setChangeEvent] = useState<AudioTimeUpdateEvent>();

  const addToLocalAutoPlaylist = (
    entry: PlaylistItemProps | ContentFeedEntrySoundpiece,
    index?: number,
  ) => {
    if (index !== undefined && index >= 0) {
      localAutoPlaylist[index] = entry;
    } else {
      // @ts-ignore because be either of the two
      localAutoPlaylist.push(entry);
    }
  };

  const setIsAutoPlay = (localIsAutoPlay: boolean) => {
    isAutoPlay = localIsAutoPlay;
  };

  const onAutoPlayNext = (localIsAutoPlay: boolean, index: number) => {
    const hasNextSoundpiece =
      localIsAutoPlay &&
      localAutoPlaylist &&
      index < localAutoPlaylist.length - 1;

    if (!hasNextSoundpiece) {
      dispatch(setIsAudioPlaying(false));

      return;
    }

    const newActiveIndex = index + 1;
    onPlay(newActiveIndex, localAutoPlaylist[newActiveIndex]);
  };

  function onTimeUpdate() {
    const { currentTime, duration, paused: isPaused } = audio;
    setChangeEvent({ currentTime, duration, isPaused });
  }

  const playAudio = () => {
    // Stop Radio
    radioPlayer?.stop();
    // Stop video
    dispatch(setCurrentPlayingVideo(null));

    audio.play();
    dispatch(setIsAudioPlaying(true));
  };

  const onPlay = (
    index: number,
    localEntry: ContentFeedEntrySoundpiece | PlaylistItemProps,
  ) => {
    dispatch(setCurrentAudio(localEntry));

    if (localEntry.audioUrl && audio.src !== localEntry.audioUrl) {
      audio.setAttribute('src', localEntry.audioUrl);
      audio.load();
    }

    audio.onended = () => onAutoPlayNext(!!isAutoPlay, index);
    playAudio();
  };

  const onResume = () => playAudio();

  const onPause = () => {
    audio?.pause();
    dispatch(setIsAudioPlaying(false));
  };

  const onStop = useCallback(() => {
    audio?.pause();
    audio?.removeAttribute('src');
    dispatch(setIsAudioPlaying(false));
  }, [dispatch]);

  const skipAudio = (step: number) => {
    audio.currentTime += step;
  };

  useEffect(() => {
    // Inital Audio setup
    if (!audio) {
      audio = new Audio();
    }

    // add onTimeUpadte for each hook instance
    audio.addEventListener('timeupdate', onTimeUpdate);
  }, []);

  useEffect(() => {
    if (audio) {
      audio.volume = isMuted ? 0 : volume / 100;
    }
  }, [volume, isMuted]);

  useEffect(() => {
    if (!currentAudio) {
      onStop();
    }
  }, [currentAudio, dispatch, onStop]);

  return {
    onPlay,
    onPause,
    onStop,
    onResume,
    setIsAutoPlay,
    changeEvent,
    skipAudio,
    addToAutoPlaylist: addToLocalAutoPlaylist,
  };
};

export default useAudioPlayer;
